// Variables

$primary-color: #30ced8;
$primary-color-darker-5: darken($primary-color, 5%);
$primary-color-lighten-30: lighten($primary-color, 30%);
$primary-color-darker: #427a8c;
$primary-color-slave: #c9abdf;
$primary-color-darker-slave: #9c69ad;
$ray-darker: lighten(#000, 13.5%);
$gray-dark: lighten(#000, 20%);
$gray: lighten(#000, 33.5%);
$gray-light: lighten(#000, 60%);
$gray-lighter: lighten(#000, 93.5%);
$gray-lightest: lighten(#000, 97.25%);
$brand-primary: #30ced8;
$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

